import { SETTINGS_PACK_FRAGMENT } from '#settings-pack/fragment'
import { FIELD_FRAGMENT } from '#field/fragment'

export const SETTINGS_PACKS_QUERY = gql`
  ${SETTINGS_PACK_FRAGMENT}
  query SettingsPacksQuery($workspaceId: ID!) {
    settingsPacks(workspaceId: $workspaceId) {
      ...SettingsPack
    }
  }
`

export const SETTINGS_PACK_QUERY = gql`
  ${SETTINGS_PACK_FRAGMENT}
  query SettingsPackQuery($id: ID!) {
    settingsPack(id: $id) {
      ...SettingsPack
    }
  }
`

export const SETTINGS_PACK_CUSTOM_FIELDS_QUERY = gql`
  ${FIELD_FRAGMENT}
  query SettingsPackFieldsQuery($id: ID!) {
    settingsPackFields(id: $id) {
      ...Field
    }
  }
`

export const ADD_UPDATE_SETTINGS_PACK_MUTATION = gql`
  ${SETTINGS_PACK_FRAGMENT}
  mutation AddUpdateSettingsPackMutation($input: AddUpdateSettingsPackInput!) {
    addUpdateSettingsPack(input: $input) {
      settingsPack {
        ...SettingsPack
      }
    }
  }
`

export const DELETE_SETTINGS_PACK_MUTATION = gql`
  mutation DeleteSettingsPackMutation($id: ID!) {
    deleteSettingsPack(id: $id) {
      success
    }
  }
`

export const TOGGLE_ASSIGN_SETTINGS_PACK_MUTATION = gql`
  ${SETTINGS_PACK_FRAGMENT}
  mutation ToggleAssignSettingsPackMutation($boards: [ID!]!, $id: ID!) {
    toggleAssignSettingsPack(boards: $boards, id: $id) {
      settingsPack {
        ...SettingsPack
      }
    }
  }
`
